import  { createStore }  from  '@stencil/store' ;

export const { state, onChange }= createStore({
  projects: [],
  apps: [],
  users: [],
  isAuthenticated: false,
});

onChange('projects', (projects) => {
  localStorage.setItem('projects', JSON.stringify(projects));
});

onChange('apps', (apps) => {
  localStorage.setItem('apps', JSON.stringify(apps));
});

onChange('users', (users) => {
  localStorage.setItem('users', JSON.stringify(users));
});